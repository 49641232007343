import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroCarousel from "../components/Home/HeroCarousel";
import { Helmet } from "react-helmet";
import SummaryBlock from "../components/Home/SummaryBlock";
import FeatureSection from "../components/Home/FeaturedSection";
import StatisticBox from "../components/Home/StatisticBlock";
import ContentDividerBox from "../components/Home/ContentDivider";
import GetInvolved from "../components/Home/GetInvolved";
import LogoSection from "../components/Home/LogoSection";
import styled from "styled-components";
import UpreachNewsCarousel from "../components/Home/UpreachNewsCarousel";
import Highlights from "../components/Home/Highlights/Highlights";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import CivicIntegration from "../components/Cookies/Cookies";
import GoogleConsentModeScript from "../components/Cookies/GoogleConsentMode";

const StyledWrapper = styled.div`
  .title-section {
    line-height: 40px;
    font-weight: 500;
    padding: 2% 5%;
    text-align: center;
    @media (max-width: 600px) {
      line-height: 20px;
    }
  }

  .home-hero-text {
    margin-top: 8px;
  }
`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IndexPage />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/home-page`);
};

const fetchStats = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stats`);
};
const IndexPage = () => {
  const [newData, setNewData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const { data: pageData } = useQuery("homepage", fetchData);
  const { data: stats } = useQuery("homePageStats", fetchStats);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (stats !== undefined) {
      setStatsData(stats.data);
    }
  }, [pageData, stats]);

  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
          <meta name="robots" content="noindex" />
      </Helmet>
      {newData === null && <Loader />}
      {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
      <StyledWrapper>
        {newData && newData.showTitleText && (
          <div className="padding-left-8 padding-right-8 home-hero-text">
            {newData.TitleText && (
              <h2 className="title-section"> {newData.TitleText}</h2>
            )}
          </div>
        )}
        {newData &&
          newData.showHeroCarousel &&
          newData.HeroCarousel.length > 0 && (
            <HeroCarousel carouselData={newData.HeroCarousel} />
          )}
        {newData && newData.showSummary && newData.summary && (
          <SummaryBlock summary={newData.summary} />
        )}
        {newData &&
          newData.showFeatureSection &&
          newData.FeatureSection.length > 0 && (
            <FeatureSection featureData={newData.FeatureSection} />
          )}
        {newData &&
          newData.showStatistics &&
          statsData &&
          statsData.length > 0 &&
          statsData.map(
            item =>
              item.slug.toLowerCase() === "Homepage".toLowerCase() &&
              item.StudentStatistics.length > 0 && (
                <StatisticBox statistic={item.StudentStatistics} />
              )
          )}
        {newData &&
          newData.showContentDivider &&
          newData.ContentDivider.length > 0 && (
            <ContentDividerBox dividerData={newData.ContentDivider} />
          )}
        {newData && newData.showInvolved && newData.involved && (
          <GetInvolved data={newData.involved} />
        )}
        {/* <UpreachNewsCarousel /> */}
        {/* <TweetCarousel /> */}
        {/* <Highlights /> */}
        {newData && newData.showLogos && (
          <LogoSection
            logos={newData.LogoNew.logos}
            logoSectionHeading={newData.LogoNew}
          />
        )}
      </StyledWrapper>
      <CivicIntegration></CivicIntegration>
      <GoogleConsentModeScript></GoogleConsentModeScript>
    </Layout>
  );
};

// export default IndexPage;
